import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Network } from '@awesome-cordova-plugins/network/ngx';

export enum ConnectionStatus {
  UNKNOWN = 'unknown',
  ETHERNET = 'ethernet',
  WIFI = 'wifi',
  CELL_2G = '2g',
  CELL_3G = '3g',
  CELL_4G = '4g',
  CELL = 'cellular',
  NONE = 'none',
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService implements OnDestroy {

  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.NONE);

  private connectSubsctiption: Subscription;
  private disconnectSubscription: Subscription;

  constructor(private network: Network, private plt: Platform) {
    this.plt.ready().then(() => {
      this.initializeNetworkEvents();
      this.status.next(this.getConnectionStatus());
    });
  }

  public initializeNetworkEvents() {

    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      if (this.status.getValue() !== ConnectionStatus.NONE) {
        console.log('WE ARE OFFLINE');
        this.updateNetworkStatus(this.getConnectionStatus());
      }
    });

    this.connectSubsctiption = this.network.onConnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.NONE) {
        console.log('WE ARE ONLINE');
        this.updateNetworkStatus(this.getConnectionStatus());
      }
    });

  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public onConnect(){
    return this.network.onConnect()
  }

  public onDisconnect(){
    return this.network.onDisconnect()
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
  }

  private getConnectionStatus(): ConnectionStatus {
    let status: ConnectionStatus;
    switch( this.network.type ) {
      case 'unknown' : status = ConnectionStatus.UNKNOWN ; break;
      case 'ethernet' : status = ConnectionStatus.ETHERNET; break;
      case 'wifi' : status = ConnectionStatus.WIFI ; break;
      case '2g' : status = ConnectionStatus.CELL_2G ; break;
      case '3g' : status = ConnectionStatus.CELL_3G ; break;
      case '4g' : status = ConnectionStatus.CELL_4G ; break;
      case 'cellular' : status = ConnectionStatus.CELL ; break;
      case 'none' : status = ConnectionStatus.NONE ; break;
    }
    return status;
  }

  public getChunkSize() {
    const currentStatus = this.status.getValue()
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  ngOnDestroy(): void {
    this.connectSubsctiption.unsubscribe();
    this.disconnectSubscription.unsubscribe();
  }
}
