import { ImageObject } from './imageObject';
import { Type } from 'class-transformer';

export class ConservatoryReport {

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  private _client: string;
  public get client(): string {
    return this._client;
  }
  public set client(value: string) {
    this._client = value;
  }
  private _isFinished: boolean = false;
  public get isFinished(): boolean {
    return this._isFinished;
  }
  public set isFinished(value: boolean) {
    this._isFinished = value;
  }

  private _mission: number;
  public get mission(): number {
    return this._mission;
  }
  public set mission(value: number) {
    this._mission = value;
  }

  private _commission: string;
  public get commission(): string {
    return this._commission;
  }
  public set commission(value: string) {
    this._commission = value;
  }

  private _serviceUnit: string;
  public get serviceUnit(): string {
    return this._serviceUnit;
  }
  public set serviceUnit(value: string) {
    this._serviceUnit = value;
  }
  private _serviceUnitCompany: string;
  public get serviceUnitCompany(): string {
    return this._serviceUnitCompany;
  }
  public set serviceUnitCompany(value: string) {
    this._serviceUnitCompany = value;
  }
  private _serviceUnitAddress: string;
  public get serviceUnitAddress(): string {
    return this._serviceUnitAddress;
  }
  public set serviceUnitAddress(value: string) {
    this._serviceUnitAddress = value;
  }

  private _date: string;
  public get date(): string {
    return this._date;
  }
  public set date(value: string) {
    this._date = value;
  }

  private _floor: number;
  public get floor(): number {
    return this._floor;
  }
  public set floor(value: number) {
    this._floor = value;
  }

  private _down: boolean;
  public get down(): boolean {
    return this._down;
  }
  public set down(value: boolean) {
    this._down = value;
  }
  private _triangleWindow: boolean;
  public get triangleWindow(): boolean {
    return this._triangleWindow;
  }
  public set triangleWindow(value: boolean) {
    this._triangleWindow = value;
  }

  private _OKFRFB: boolean;
  public get OKFRFB(): boolean {
    return this._OKFRFB;
  }
  public set OKFRFB(value: boolean) {
    this._OKFRFB = value;
  }

  private _downText: string;
  public get downText(): string {
    return this._downText;
  }
  public set downText(value: string) {
    this._downText = value;
  }
  private _triangleWindowText: string;
  public get triangleWindowText(): string {
    return this._triangleWindowText;
  }
  public set triangleWindowText(value: string) {
    this._triangleWindowText = value;
  }

  private _OKFRFBtext: string;
  public get OKFRFBtext(): string {
    return this._OKFRFBtext;
  }
  public set OKFRFBtext(value: string) {
    this._OKFRFBtext = value;
  }

  private _specifics: string;
  public get specifics(): string {
    return this._specifics;
  }
  public set specifics(value: string) {
    this._specifics = value;
  }
  @Type(() => ImageObject)
  private _triangleRight: ImageObject;
  public get triangleRight(): ImageObject {
    if(this._triangleRight === undefined) {
      this._triangleRight = new ImageObject()
    }
    return this._triangleRight;
  }
  @Type(() => ImageObject)
  public set triangleRight(value: ImageObject) {
    this._triangleRight = value;
  }
  @Type(() => ImageObject)
  private _triangleLeft: ImageObject;
  public get triangleLeft(): ImageObject {
    if(this._triangleLeft === undefined) {
      this._triangleLeft = new ImageObject()
    }
    return this._triangleLeft;
  }
  @Type(() => ImageObject)
  public set triangleLeft(value: ImageObject) {
    this._triangleLeft = value;
  }
  @Type(() => ImageObject)
  private _square1: ImageObject;
  public get square1(): ImageObject {
    if(this._square1 === undefined) {
      this._square1 = new ImageObject()
    }
    return this._square1;
  }
  @Type(() => ImageObject)
  public set square1(value: ImageObject) {
    this._square1 = value;
  }
  @Type(() => ImageObject)
  private _square2: ImageObject;
  public get square2(): ImageObject {
    if(this._square2 === undefined) {
      this._square2 = new ImageObject()
    }
    return this._square2;
  }
  @Type(() => ImageObject)
  public set square2(value: ImageObject) {
    this._square2 = value;
  }
  private _created: Date;
  public get created(): Date {
    return this._created;
  }
  public set created(value: Date) {
    this._created = value;
  }

  constructor() {
    this._created = new Date();
  }
}