export enum ActivityStatus {
  new = 2,
  approved = 3,
  transfer = 4,
  edit = 5,
  finish = 6,
  reportsTransfered = 7,
  imagesInProgress = 8,
  complete = 9,
  sent = 10
}

export enum Reports {
  CONSERVATORY_REPORT = "conservatoryReport",
  ACCEPTANCE_REPORT = "acceptanceReport",
  RC2CERTIFICATE_REPORT = "rc2CertificateReport",
  SERVICE_REPORT = "serviceReport",
  GLAS_REPORT = "glasReport",
  CHECKLIST_REPORT = "checkReport",
  MESSAGE = "message"
}