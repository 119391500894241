import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { take } from 'rxjs';

/*
  Generated class for the TranslationServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class TranslationServiceProvider {

  private logger: Logger
  private translateServiceInstance: TranslateService;

  constructor(loggerService:LoggingService, translateService: TranslateService) {
    // this.logger = loggerService.getLogger(environment.appName + ".TranslationService")
    // this.logger.debug("constructor", "class" + " " + "TranslationServiceProvider" + " " + "constructor");
    this.translateServiceInstance = translateService;
    this.translateServiceInstance.use('de');

    
  }

  /**
   * 
   * @param key array of translation keys
   * @returns an Observable that emits once, then completes
   */
  public translate(key: Array<string>) {
    return this.translateServiceInstance.get(key).pipe(
      take(1)
    )
  }

}
