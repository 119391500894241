/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { SecureStorageService, UserKeys } from './secure-storage.service';
import { ConnectionStatus, NetworkService } from 'src/app/shared/utils/http/network.service';

import { Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import p from 'package.json';

export interface Credentials {
  user_name: string;
  password: string;
}

interface LoginResponse {
  user_name: string;
  timestamp: number;
  valid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(null);

  constructor(private http: HttpClient, private secureStorage: SecureStorageService, private network: NetworkService, private platform:Platform) { }

  get isLoggedIn(): Observable<boolean> {
    return this.loggedIn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  login(credentials: Credentials): Observable<LoginResponse> {
    let login$: Observable<LoginResponse>;
    if(this.network.getCurrentNetworkStatus() === ConnectionStatus.NONE) {
      login$ = this.offlineLogin(credentials);
    } else {
      login$ = this.onlineLogin(credentials);
    }

    let version;
    // get current app version
    if(!this.platform.is('android') || this.platform.is('pwa')){ // android-pwa, desktop oder ios
      version = p.version;
      //alert(p.version);
    }
    else{
      App.getInfo()
      .then(info => {
        version = info.version;
      })
    }

    return login$.pipe(
      map(response => {
        this.secureStorage.set(UserKeys.USERNAME, credentials.user_name);
        this.secureStorage.set(UserKeys.PASSWORD, credentials.password);
        this.secureStorage.set(UserKeys.LOGINTIMESTAMP, response.timestamp.toString());
        this.secureStorage.set(UserKeys.ISLOGGEDIN, 'true');
        this.secureStorage.set(UserKeys.APPVERSION, version)
        this.loggedIn.next(response.valid);
        return response;
      })
    );
  }

  async logout() {
    await Promise.all([
      this.secureStorage.set(UserKeys.LOGOUTTIMESTAMP, new Date().getTime().toString()),
      this.secureStorage.set(UserKeys.ISLOGGEDIN, 'false')
    ]);
    this.loggedIn.next(false);
  }

  private onlineLogin(credentials: Credentials): Observable<LoginResponse> {
    const postData = new HttpParams({
      fromObject: {...credentials, action: 'login'}
    });
    return this.http.post<LoginResponse>(environment.apiUrl, postData);
  }

  private offlineLogin(credentials: Credentials): Observable<LoginResponse> {
    return this.secureStorage.getCredentials().pipe(
      map((savedCredentials) => {
        if(credentials.user_name === savedCredentials.user_name && credentials.password === savedCredentials.password) {
          return {user_name: savedCredentials.user_name, timestamp: new Date().getTime(), valid: true};
        }
        throw throwError({error: {message: { error_code: 1000, message: 'Bad Authentification data' }}});
      })
    );
  }

  resetPassword(credentials: Credentials): Observable<any>{
    const postData = new HttpParams({
      fromObject: {...credentials, action: 'reset_password'}
    });
    return this.http.post<any>(environment.apiUrl, postData);
  }

  sendRequestMessage(credentials: Credentials, message: string): Observable<any>{
    const postData = new HttpParams({
      fromObject: {
        ...credentials, 
        action: 'send_request_message', 
        message: message}
    });
    return this.http.post<any>(environment.apiUrl, postData)
  }
  
}
