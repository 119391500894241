import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { forkJoin, from, Observable, switchMap } from 'rxjs';
import { TranslationServiceProvider } from '../../i18n/translation-service';

@Injectable({
  providedIn: 'root'
})
export class GlobalAlertService {

  private defaultTranslations
  constructor(private alertCtrl: AlertController, private translationService: TranslationServiceProvider) {
    this.defaultTranslations = this.translationService.translate(['btn.OK', 'btn.Continue', 'btn.Cancel', 'alert.title', 'btn.yes', 'btn.no'])
  }

  /**
   * Displays an Alert with given title and message.
   * @param title Title of the Alert. If null the translation for alert.title (DE = Hinweis) is used
   * @param translateKey translation key or array of translation keys. If translateKey is an array, each translation will appear on a new line
   */
   alert(title: string, translateKey: string | string[]): Observable<HTMLIonAlertElement> {
    let message: string = ""
    if(!Array.isArray(translateKey)) {
      translateKey = [translateKey]
    }
    return forkJoin( {
      message: this.translationService.translate(translateKey),
      default: this.defaultTranslations //this.translationService.translate(['alert.title', 'btn.OK']) 
    }).pipe(
      switchMap(translations => {
        //console.log("translations", translations)
        const temp = []
        for(let key in translations.message) {
          temp.push(translations.message[key])
        }
        const message = temp.join(',<br>')
        //console.log(message)
        return from(this.alertCtrl.create({
          header: title === undefined ? translations.default['alert.title'] : title,
          message: message,
          buttons: [translations.default['btn.OK']]
        }))
      })
    )
  }

  /**
   * Displays an Alert with given title and message.
   * @param title Title of the Alert. If null the translation for alert.title (DE = Hinweis) is used
   * @param translateKey translation key or array of translation keys. If translateKey is an array, each translation will appear on a new line
   */
   alertRelease(title: string, translateKey: string | string[]): Observable<HTMLIonAlertElement> {
    let message: string = ""
    if(!Array.isArray(translateKey)) {
      translateKey = [translateKey]
    }
    return forkJoin( {
      message: this.translationService.translate(translateKey),
      default: this.defaultTranslations //this.translationService.translate(['alert.title', 'btn.OK']) 
    }).pipe(
      switchMap(translations => {
        //console.log("translations", translations)
        const temp = []
        for(let key in translations.message) {
          temp.push(translations.message[key])
        }
        const message = temp.join(',<br>')
        //console.log(message)

        let splitmessage = message.split(';').map(e => e.split(','));
        //console.log(splitmessage)

        let showmessage = '<ul>'
        for(let i = 0; i < splitmessage.length; i++)
        {
          showmessage += '<li>'+ splitmessage[i] + '</li>';
        }
        showmessage += '</ul>'

        return from(this.alertCtrl.create({
          header: title === undefined ? translations.default['alert.title'] : title,
          message: showmessage,
          buttons: [translations.default['btn.OK']]
        }))
      })
    )
  }

  confirm(message, confirmHandler,cancelHandler, additionalMessage?): Observable<HTMLIonAlertElement> {
    return forkJoin({
      message: this.translationService.translate([message]),
      default: this.defaultTranslations //this.translationService.translate(['btn.OK', 'btn.Cancel', 'alert.title'])
    }).pipe(
      switchMap(translations => {
        //console.log("translations", translations)
        return from(this.alertCtrl.create({
          header: translations.default['alert.title'],
          message: additionalMessage ? translations.message[message] + additionalMessage : translations.message[message],
          buttons: [
            {
              text: translations.default['btn.OK'],
              handler: confirmHandler
            },
            {
              text: translations.default['btn.Cancel'],
              handler: cancelHandler
            }
          ]
        }))
      })
    )
  }

  request(title, message, confirmHandler,cancelHandler): Observable<HTMLIonAlertElement> {
    return forkJoin({
      translations: this.translationService.translate([title, message]),
      default: this.defaultTranslations
    }).pipe(
      switchMap(translations => {
        console.log("translations", translations)
        return from(this.alertCtrl.create({
          header: title ? translations.translations[title] : translations.default['alert.title'],
          message: translations.translations[message],
          buttons: [{
            text: translations.default['btn.yes'],
            handler: confirmHandler
          }, {
            text: translations.default['btn.no'],
            handler: cancelHandler
          }]
        }))
      })
    )

    /* let alert = this.alertCtrl.create({
      title: title ? this.translations[title] : this.translations["alert.title"],
      message: this.translations[message],
      buttons: [{
        text: this.translations["btn.yes"],
        handler: confirmHandler
      },
      {
        text: this.translations["btn.no"],
        handler: cancelHandler
      }]
    });
    await alert.present(); */
  }

  requestStart(title, message, confirmHandler,cancelHandler): Observable<HTMLIonAlertElement> {
    return forkJoin({
      translations: this.translationService.translate([title, message]),
      default: this.defaultTranslations
    }).pipe(
      switchMap(translations => {
        console.log("translations", translations)
        return from(this.alertCtrl.create({
          header: title ? translations.translations[title] : translations.default['alert.title'],
          message: translations.translations[message],
          buttons: [{
            text: translations.default['btn.Continue'],
            handler: confirmHandler
          }, {
            text: translations.default['btn.Cancel'],
            handler: cancelHandler
          }]
        }))
      })
    )
  }
}
