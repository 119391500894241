import { HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AuthService } from '../data-access/auth.service';
import { SecureStorageService, UserKeys } from '../data-access/secure-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {

  constructor(private authService: AuthService, private secureStorage: SecureStorageService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return forkJoin([
      this.secureStorage.getCredentials(),
      this.secureStorage.get(UserKeys.ISLOGGEDIN)
    ]).pipe(
      switchMap(([credentials, isLoggedIn]) => {
        if(JSON.parse(isLoggedIn.value)) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          return this.authService.login(credentials);
        }
        return of({ valid: false });
    }),
      map(response => {
        if(response.valid) {
          this.router.navigateByUrl('/order', { replaceUrl: true });
        }
        return true;
      }),
      catchError(error => of(true)) // allow access to login page if an error happens, for example when secure storage keys are not set
    );
  }
}
