
export class DegreeObject {

    constructor(){

    }

    private _depth: number;
  public get depth(): number {
    return this._depth;
  }
  public set depth(value: number) {
    this._depth = value;
  }
  private _length: number;
  public get length(): number {
    return this._length;
  }
  public set length(value: number) {
    this._length = value;
  }
  private _width: number;
  public get width(): number {
    return this._width;
  }
  public set width(value: number) {
    this._width = value;
  }

}