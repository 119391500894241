import { ExecutedWork } from './executedWork';
import { Exclude, Type } from 'class-transformer';
import PointGroup from 'signature_pad';

export class ServiceReport {

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  private _customerId: number;
  public get customerId(): number {
    return this._customerId;
  }
  public set customerId(value: number) {
    this._customerId = value;
  }
  
  private _isFinished: boolean = false;
  public get isFinished(): boolean {
    return this._isFinished;
  }
  public set isFinished(value: boolean) {
    this._isFinished = value;
  }

  private _client: string;
  public get client(): string {
    return this._client;
  }
  public set client(value: string) {
    this._client = value;
  }

  private _mission: number;
  public get mission(): number {
    return this._mission;
  }
  public set mission(value: number) {
    this._mission = value;
  }

  private _commission: string;
  public get commission(): string {
    return this._commission;
  }
  public set commission(value: string) {
    this._commission = value;
  }

  private _address: string;
  public get address(): string {
    return this._address;
  }
  public set address(value: string) {
    this._address = value;
  }


  private _serviceUnit: string;
  public get serviceUnit(): string {
    return this._serviceUnit;
  }
  public set serviceUnit(value: string) {
    this._serviceUnit = value;
  }
  private _serviceUnitCompany: string;
  public get serviceUnitCompany(): string {
    return this._serviceUnitCompany;
  }
  public set serviceUnitCompany(value: string) {
    this._serviceUnitCompany = value;
  }
  private _serviceUnitAddress: string;
  public get serviceUnitAddress(): string {
    return this._serviceUnitAddress;
  }
  public set serviceUnitAddress(value: string) {
    this._serviceUnitAddress = value;
  }

  private _date: string;
  public get date(): string {
    return this._date;
  }
  public set date(value: string) {
    this._date = value;
  }
  private _timeBegin: string;
  public get timeBegin(): string {
    return this._timeBegin;
  }
  public set timeBegin(value: string) {
    this._timeBegin = value;
  }
  private _timeEnd: string;
  public get timeEnd(): string {
    return this._timeEnd;
  }
  public set timeEnd(value: string) {
    this._timeEnd = value;
  }

  private _km: number;
  public get km(): number {
    return this._km;
  }
  public set km(value: number) {
    this._km = value;
  }

  @Type(() => ExecutedWork)
  private _executedWorks: ExecutedWork[];
  public get executedWorks(): ExecutedWork[] {
    return this._executedWorks;
  }
  @Type(() => ExecutedWork)
  public set executedWorks(value: ExecutedWork[]) {
    this._executedWorks = value;
  }
  private _maintenance: boolean;
  public get maintenance(): boolean {
    return this._maintenance;
  }
  public set maintenance(value: boolean) {
    this._maintenance = value;
  }

  private _newMaintenanceOpt: boolean;
  public get newMaintenanceOpt(): boolean {
    return this._newMaintenanceOpt;
  }
  public set newMaintenanceOpt(value: boolean) {
    this._newMaintenanceOpt = value;
  }

  private _guaranteeDescription: string;
  public get guaranteeDescription(): string {
    return this._guaranteeDescription;
  }
  public set guaranteeDescription(value: string) {
    this._guaranteeDescription = value;
  }
  private _guarantee: boolean;
  public get guarantee(): boolean {
    return this._guarantee;
  }
  public set guarantee(value: boolean) {
    this._guarantee = value;
  }

  private _disclaimerText: string;
  public get disclaimerText(): string {
    return this._disclaimerText;
  }
  public set disclaimerText(value: string) {
    this._disclaimerText = value;
  }

  private _claim: boolean;
  public get claim(): boolean {
    return this._claim;
  }
  public set claim(value: boolean) {
    this._claim = value;
  }

  private _remainingWorkNecessary: boolean;
  public get remainingWorkNecessary(): boolean {
    return this._remainingWorkNecessary;
  }
  public set remainingWorkNecessary(value: boolean) {
    this._remainingWorkNecessary = value;
  }
  private _signatureServiceUnit: string;
  public get signatureServiceUnit(): string {
    return this._signatureServiceUnit;
  }
  public set signatureServiceUnit(value: string) {
    this._signatureServiceUnit = value;
  }
  private _hasSignatureCustomer: boolean;
  public get hasSignatureCustomer(): boolean {
    return this._hasSignatureCustomer;
  }
  public set hasSignatureCustomer(value: boolean) {
    this._hasSignatureCustomer = value;
  }
  private _signatureCustomer: string;
  public get signatureCustomer(): string {
    return this._signatureCustomer;
  }
  public set signatureCustomer(value: string) {
    this._signatureCustomer = value;
  }
  private _signatureServiceUnitDate: Date
  public get signatureServiceUnitDate() : Date {
    return this._signatureServiceUnitDate
  }
  public set signatureServiceUnitDate(value: Date) {
    this._signatureServiceUnitDate = value
  }
  private _signatureCustomerDate: Date
  public get signatureCustomerDate() : Date {
    return this._signatureCustomerDate
  }
  public set signatureCustomerDate(value: Date) {
    this._signatureCustomerDate = value
  }
  private _noClientAvailable: boolean = false;
  public get noClientAvailable(): boolean {
    return this._noClientAvailable;
  }
  public set noClientAvailable(value: boolean) {
    this._noClientAvailable = value;
  }
  private _created: Date;
  public get created(): Date {
    return this._created;
  }
  public set created(value: Date) {
    this._created = value;
  }
  @Exclude({ toPlainOnly: true })
  private _signatureCustomerPoints: PointGroup[];
  public get signatureCustomerPoints(): PointGroup[] {
    return this._signatureCustomerPoints;
  }
  public set signatureCustomerPoints(value: PointGroup[]) {
    this._signatureCustomerPoints = value;
  }
  @Exclude({ toPlainOnly: true })
  private _signatureServiceUnitPoints: PointGroup[];
  public get signatureServiceUnitPoints(): PointGroup[] {
    return this._signatureServiceUnitPoints;
  }
  public set signatureServiceUnitPoints(value: PointGroup[]) {
    this._signatureServiceUnitPoints = value;
  }

  private _newMaintenanceRemarks : string;
  public get newMaintenanceRemarks() : string {
    return this._newMaintenanceRemarks;
  }

  public set newMaintenanceRemarks(value: string) {
    this._newMaintenanceRemarks = value;
  }

  private _remainingWork : string;
  public get remainingWork() : string {
    return this._remainingWork;
  }
  public set remainingWork(value: string) {
    this._remainingWork = value;
  }

  private _offerText : string;
  public get offerText() : string {
    return this._offerText;
  }
  public set offerText(value: string) {
    this._offerText = value;
  }

  private _orderText: string;
  public get orderText() : string {
    return this._orderText;
  }
  public set orderText(value: string) {
    this._orderText = value;
  }

  constructor() {
    this._created = new Date();
    this._hasSignatureCustomer = true;
    this._signatureCustomerPoints = [];
    this._signatureServiceUnitPoints = [];
    this._executedWorks = []
  }

  private static serializeExecutedWorks(value){
    //console.log("value",value);
    let returnValue = {};
    for(let i = 0; i < value.length; i++){
      let WorkItem = value[i];
      returnValue[WorkItem.name] = WorkItem.time;
    }
    return returnValue;
  }
}