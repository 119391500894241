import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggingService, LoggingServiceModule } from 'ionic-logging-service';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ContentTypeInterceptor } from './shared/utils/http/content-type-interceptor';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Diagnostic} from '@awesome-cordova-plugins/diagnostic/ngx';
import { TranslationServiceProvider } from './shared/utils/i18n/translation-service';
import { GlobalAlertService } from './shared/utils/ui/alert/global-alert.service';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function configureLogging(loggingService: LoggingService): () => void {
  return () => loggingService.configure(environment.logging);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LoggingServiceModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IonicStorageModule.forRoot({
      driverOrder: [cordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      deps: [LoggingService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: configureLogging
    },
    /*{
      provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true
    },*/
    Network,
    LaunchNavigator,
    Geolocation,
    Diagnostic,
    TranslationServiceProvider,
    GlobalAlertService,
    FileOpener
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
