/*
 * Klasse für ein Datenobjekt eines Photos
 * Datenobjekt
 */
export class Photo {
    private _date: Date;
  public get date(): Date {
    return this._date;
  }
  public set date(value: Date) {
    this._date = value;
  }

    private _url: string;
  public get url(): string {
    return this._url;
  }
  public set url(value: string) {
    this._url = value;
  }
  
  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }

  private _transfered: boolean = false;
  public get transfered(): boolean {
    return this._transfered;
  }

  private _transferedPercentage:number = 0;
  public get transferedPercentage():number{
    return this._transferedPercentage;
  }
  public set transferedPercentage(value:number){
    this._transferedPercentage = value;
  }
  public set transfered(value: boolean) {
    this._transfered = value;
  }

    /*
   * Konstruktor der Klasse
   */
  constructor() { }
}