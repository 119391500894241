import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { AutoLoginGuard } from './auth/guards/auto-login.guard';

const routes: Routes = [
  {
    path: 'order',
    loadChildren: () => import('./order/feature/order-shell/order-shell-routing.module').then( m => m.OrderShellRoutingModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/feature/login/login.module').then( m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'transferstatus',
    loadChildren: () => import('./transferstatus/feature/transferstatus/transferstatus.module').then( m => m.TransferstatusPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./auth/feature/login/login.module').then( m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
