export class Message {

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  private _client: string;
  public get client(): string {
    return this._client;
  }
  public set client(value: string) {
    this._client = value;
  }
  private _isFinished: boolean = false;
  public get isFinished(): boolean {
    return this._isFinished;
  }
  public set isFinished(value: boolean) {
    this._isFinished = value;
  }

  private _mission: number;
  public get mission(): number {
    return this._mission;
  }
  public set mission(value: number) {
    this._mission = value;
  }

  private _commission: string;
  public get commission(): string {
    return this._commission;
  }
  public set commission(value: string) {
    this._commission = value;
  }


  private _serviceUnit: string;
  public get serviceUnit(): string {
    return this._serviceUnit;
  }
  public set serviceUnit(value: string) {
    this._serviceUnit = value;
  }

  private _date: string;
  public get date(): string {
    return this._date;
  }
  public set date(value: string) {
    this._date = value;
  }

  private _created: Date;
  public get created(): Date {
    return this._created;
  }
  public set created(value: Date) {
    this._created = value;
  }

  private _report: string;
  public get report(): string {
    return this._report;
  }
  public set report(value: string) {
    this._report = value;
  }

  private _comment: string;
  public get comment(): string {
    return this._comment;
  }
  public set comment(value: string) {
    this._comment = value;
  }
  
  constructor() {
    this._created = new Date();
  }
}