export class RCPositionItem {
  
  constructor(){}

  private _location: string;
  public get location(): string{
    return this._location;
  }
  public set location(value: string){
    this._location = value;
  }

  private _rcClass: string;
  public get rcClass(): string{
    return this._rcClass;
  }
  public set rcClass(value: string){
    this._rcClass = value;
  }

  private _remark: string;
  public get remark(): string{
    return this._remark;
  }
  public set remark(value: string){
    this._remark = value;
  }
}