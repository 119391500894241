import { DegreeObject } from './degreeObject';
import { DamageObject } from './damageObject';
import { Exclude, Type } from 'class-transformer';
import PointGroup from 'signature_pad'

export class GlasReport {

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  private _client: string;
  public get client(): string {
    return this._client;
  }
  public set client(value: string) {
    this._client = value;
  }
  private _isFinished: boolean = false;
  public get isFinished(): boolean {
    return this._isFinished;
  }
  public set isFinished(value: boolean) {
    this._isFinished = value;
  }

  private _mission: number;
  public get mission(): number {
    return this._mission;
  }
  public set mission(value: number) {
    this._mission = value;
  }

  private _commission: string;
  public get commission(): string {
    return this._commission;
  }
  public set commission(value: string) {
    this._commission = value;
  }


  private _serviceUnit: string;
  public get serviceUnit(): string {
    return this._serviceUnit;
  }
  public set serviceUnit(value: string) {
    this._serviceUnit = value;
  }
  private _serviceUnitCompany: string;
  public get serviceUnitCompany(): string {
    return this._serviceUnitCompany;
  }
  public set serviceUnitCompany(value: string) {
    this._serviceUnitCompany = value;
  }
  private _serviceUnitAddress: string;
  public get serviceUnitAddress(): string {
    return this._serviceUnitAddress;
  }
  public set serviceUnitAddress(value: string) {
    this._serviceUnitAddress = value;
  }

  private _date: string;
  public get date(): string {
    return this._date;
  }
  public set date(value: string) {
    this._date = value;
  }

  private _positionNumber: string;
  public get positionNumber(): string {
    return this._positionNumber;
  }
  public set positionNumber(value: string) {
    this._positionNumber = value;
  }
  @Type(() => DamageObject)
  private _glasSurface: DamageObject;
  public get glasSurface(): DamageObject {
    return this._glasSurface;
  }
  @Type(() => DamageObject)
  public set glasSurface(value: DamageObject) {
    this._glasSurface = value;
  }
  @Type(() => DamageObject)
  private _glasInterval: DamageObject;
  public get glasInterval(): DamageObject {
    return this._glasInterval;
  }
  @Type(() => DamageObject)
  public set glasInterval(value: DamageObject) {
    this._glasInterval = value;
  }
  @Type(() => DegreeObject)
  private _bubblesDegree: DegreeObject;
  public get bubblesDegree(): DegreeObject {
    return this._bubblesDegree;
  }
  @Type(() => DegreeObject)
  public set bubblesDegree(value: DegreeObject) {
    this._bubblesDegree = value;
  }
  @Type(() => DegreeObject)
  public set scratchDegree(value: DegreeObject) {
    this._scratchDegree = value;
  }
  @Type(() => DegreeObject)
  private _scratchDegree: DegreeObject;
  public get scratchDegree(): DegreeObject {
    return this._scratchDegree;
  }
  @Type(() => DegreeObject)
  public set angleGrinderDegree(value: DegreeObject) {
    this._angleGrinderDegree = value;
  }
  @Type(() => DegreeObject)
  private _angleGrinderDegree: DegreeObject;
  public get angleGrinderDegree(): DegreeObject {
    return this._angleGrinderDegree;
  }
  @Type(() => DegreeObject)
  public set otherDegree(value: DegreeObject) {
    this._otherDegree = value;
  }
  @Type(() => DegreeObject)
  private _otherDegree: DegreeObject;
  public get otherDegree(): DegreeObject {
    return this._otherDegree;
  }

  private _drawingImage: string;
  public get drawingImage(): string {
    return this._drawingImage;
  }
  public set drawingImage(value: string) {
    this._drawingImage = value;
  }
  private _width: number;
  public get width(): number {
    return this._width;
  }
  public set width(value: number) {
    this._width = value;
  }
  private _height: number;
  public get height(): number {
    return this._height;
  }
  public set height(value: number) {
    this._height = value;
  }

  private _signatureServiceUnit: string;
  public get signatureServiceUnit(): string {
    return this._signatureServiceUnit;
  }
  public set signatureServiceUnit(value: string) {
    this._signatureServiceUnit = value;
  }
  private _hasSignatureCustomer: boolean;
  public get hasSignatureCustomer(): boolean {
    return this._hasSignatureCustomer;
  }
  public set hasSignatureCustomer(value: boolean) {
    this._hasSignatureCustomer = value;
  }
  private _signatureCustomer: string;
  public get signatureCustomer(): string {
    return this._signatureCustomer;
  }
  public set signatureCustomer(value: string) {
    this._signatureCustomer = value;
  }
  private _noClientAvailable: boolean = false;
  public get noClientAvailable(): boolean {
    return this._noClientAvailable;
  }
  public set noClientAvailable(value: boolean) {
    this._noClientAvailable = value;
  }
  private _comment: string;
  public get comment(): string {
    return this._comment;
  }
  public set comment(value: string) {
    this._comment = value;
  }
  private _created: Date;
  public get created(): Date {
    return this._created;
  }
  public set created(value: Date) {
    this._created = value;
  }
  @Exclude({ toPlainOnly: true })
  private _signatureCustomerPoints: PointGroup[];
  public get signatureCustomerPoints(): PointGroup[] {
    return this._signatureCustomerPoints;
  }
  public set signatureCustomerPoints(value: PointGroup[]) {
    this._signatureCustomerPoints = value;
  }
  @Exclude({ toPlainOnly: true })
  private _signatureServiceUnitPoints: PointGroup[];
  public get signatureServiceUnitPoints(): PointGroup[] {
    return this._signatureServiceUnitPoints;
  }
  public set signatureServiceUnitPoints(value: PointGroup[]) {
    this._signatureServiceUnitPoints = value;
  }
  @Exclude({ toPlainOnly: true })
  private _drawingImagesPoints: PointGroup[];
  public get drawingImagesPoints(): PointGroup[] {
    return this._drawingImagesPoints;
  }
  public set drawingImagesPoints(value: PointGroup[]) {
    this._drawingImagesPoints = value;
  }

  constructor() {
    this._created = new Date();
    this._hasSignatureCustomer = true;
    this._signatureCustomerPoints = [];
    this._signatureServiceUnitPoints = [];
    this._drawingImagesPoints = [];
  }
}