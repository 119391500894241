
export class DamageObject {

    constructor(){

    }

    private _bubbles: boolean;
  public get bubbles(): boolean {
    return this._bubbles;
  }
  public set bubbles(value: boolean) {
    this._bubbles = value;
  }
  private _scratch: boolean;
  public get scratch(): boolean {
    return this._scratch;
  }
  public set scratch(value: boolean) {
    this._scratch = value;
  }

  private _angleGrinder: boolean;
  public get angleGrinder(): boolean {
    return this._angleGrinder;
  }
  public set angleGrinder(value: boolean) {
    this._angleGrinder = value;
  }
  private _other: string;
  public get other(): string {
    return this._other;
  }
  public set other(value: string) {
    this._other = value;
  }

}