export class ExecutedWork {
  
  private _time: string;
  private _name: string;
  private _checkedValue: boolean;
  private _remarks: string
  
  constructor(){}

  public get time(): string {
    return this._time;
  }
  public set time(value: string) {
    this._time = value;
  }

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }

  public get checkedValue(): boolean {
    return this._checkedValue;
  }
  public set checkedValue(value: boolean) {
    this._checkedValue = value;
  }

  public set remarks(value: string) {
      this._remarks = value;
  }
  public get remarks() : string {
      return this._remarks
  }
}