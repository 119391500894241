
export class ImageObject {

    constructor(){

    }

    private _a: string;
  public get a(): string {
    return this._a;
  }
  public set a(value: string) {
    this._a = value;
  }
  private _b: string;
  public get b(): string {
    return this._b;
  }
  public set b(value: string) {
    this._b = value;
  }
  private _c: string;
  public get c(): string {
    return this._c;
  }
  public set c(value: string) {
    this._c = value;
  }

  private _d: string;
  public get d(): string {
    return this._d;
  }
  public set d(value: string) {
    this._d = value;
  }

  private _e: string;
  public get e(): string {
    return this._e;
  }
  public set e(value: string) {
    this._e = value;
  }

}