export class ABockNumber {
  
  constructor(){}

  private _aBockNumber: string;
  public get aBockNumber(): string{
    return this._aBockNumber;
  }
  public set aBockNumber(value: string){
    this._aBockNumber = value;
  }
}