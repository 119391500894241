import { Exclude, Type } from 'class-transformer';
import PointGroup from 'signature_pad';
import { RCPositionItem } from './rcPostionItem';

export class RC2CertificateReport {

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  private _customerId: number;
  public get customerId(): number {
    return this._customerId;
  }
  public set customerId(value: number) {
    this._customerId = value;
  }

  private _client: string;
  public get client(): string {
    return this._client;
  }
  public set client(value: string) {
    this._client = value;
  }

  private _mission: number;
  public get mission(): number {
    return this._mission;
  }
  public set mission(value: number) {
    this._mission = value;
  }

  private _commission: string;
  public get commission(): string {
    return this._commission;
  }
  public set commission(value: string) {
    this._commission = value;
  }

  private _address: string;
  public get address(): string {
    return this._address;
  }
  public set address(value: string) {
    this._address = value;
  }

  private _serviceUnit: string;
  public get serviceUnit(): string {
    return this._serviceUnit;
  }
  public set serviceUnit(value: string) {
    this._serviceUnit = value;
  }
  private _serviceUnitCompany: string;
  public get serviceUnitCompany(): string {
    return this._serviceUnitCompany;
  }
  public set serviceUnitCompany(value: string) {
    this._serviceUnitCompany = value;
  }
  private _serviceUnitAddress: string;
  public get serviceUnitAddress(): string {
    return this._serviceUnitAddress;
  }
  public set serviceUnitAddress(value: string) {
    this._serviceUnitAddress = value;
  }

  private _date: string;
  public get date(): string {
    return this._date;
  }
  public set date(value: string) {
    this._date = value;
  }
  
  private _rcPositionsLength: number;
  public get rcPositionsLength(): number {
    return this._rcPositionsLength;
  }
  public set rcPositionsLength(value: number){
    this._rcPositionsLength = value;
  }
  @Type(() => RCPositionItem)
  private _rcPositions: RCPositionItem[];
  public get rcPositions(): RCPositionItem[] {
    return this._rcPositions;
  }
  @Type(() => RCPositionItem)
  public set rcPositions(value: RCPositionItem[]) {
    this._rcPositions = value;
  }  
  private _signatureServiceUnit: string;
  public get signatureServiceUnit(): string {
    return this._signatureServiceUnit;
  }
  public set signatureServiceUnit(value: string) {
    this._signatureServiceUnit = value;
  }
  private _hasSignatureCustomer: boolean;
  public get hasSignatureCustomer(): boolean {
    return this._hasSignatureCustomer;
  }
  public set hasSignatureCustomer(value: boolean) {
    this._hasSignatureCustomer = value;
  }
  private _signatureCustomer: string;
  public get signatureCustomer(): string {
    return this._signatureCustomer;
  }
  public set signatureCustomer(value: string) {
    this._signatureCustomer = value;
  }
  private _isFinished: boolean = false;
  public get isFinished(): boolean {
    return this._isFinished;
  }
  public set isFinished(value: boolean) {
    this._isFinished = value;
  }

  private _noClientAvailable: boolean;
  public get noClientAvailable(): boolean {
    return this._noClientAvailable;
  }
  public set noClientAvailable(value: boolean) {
    this._noClientAvailable = value;
  }
  private _created: Date;
  public get created(): Date {
    return this._created;
  }
  public set created(value: Date) {
    this._created = value;
  }

  @Exclude({ toPlainOnly: true })
  private _signatureServiceUnitPoints: PointGroup[];
  public get signatureServiceUnitPoints(): PointGroup[] {
    return this._signatureServiceUnitPoints;
  }
  public set signatureServiceUnitPoints(value: PointGroup[]) {
    this._signatureServiceUnitPoints = value;
  }

  constructor() {
    this._created = new Date();
    this._hasSignatureCustomer = false;
    this._signatureServiceUnitPoints = [];
    this._rcPositions = [];
  }
}