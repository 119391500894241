import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Activity } from '../../model/activity';

const ACTIVITIES_KEY = 'activities'

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy{
  private storageReady$ = new BehaviorSubject<boolean>(false)
  private activities$ = new BehaviorSubject<Activity[] | null>(null)
  private activitiesSub: Subscription

  constructor(private storage: Storage) {
    this.init()
  }

  private async init(): Promise<void> {
    await this.storage.defineDriver(cordovaSQLiteDriver)
    await this.storage.create()
    this.storageReady$.next(true)
    this.activitiesSub = this.loadActivities().subscribe(a => this.activities$.next(a))
  }

  public loadActivities(): Observable<Activity[]> {
    return this.storageReady$.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(ACTIVITIES_KEY))),
      map(activities => plainToInstance(Activity, activities as Object[])),
    )
  }

  /**
   * 
   * @returns an Observable that emits never completes
   */
  getActivities(): Observable<Activity[]> {
    return this.activities$
  }

  saveAll(activities: Activity[], config?:{ emit : boolean }){
    const plain = instanceToPlain(activities)
    this.storage.set(ACTIVITIES_KEY, activities)
    if(config?.emit) {
      this.activities$.next(activities)
    }
  }

  /**
   * Saves the activity to local storage and emits the update activity list on the datastream
   * @param activity activity to save
   */
  async saveOne(activity: Activity) {
    let current: Activity[] = this.activities$.getValue() || (await this.storage.get(ACTIVITIES_KEY))
    //console.log("current", current)
    const idx = current.findIndex(a => a.id === activity.id)
    if(idx > -1) {
      current.splice(idx, 1, activity)
      this.saveAll(current)
      this.activities$.next(current)
    }
  }

  storageReady(): Observable<boolean> {
    return this.storageReady$
  }

  ngOnDestroy(): void {
      this.activitiesSub.unsubscribe()
  }
}
