/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Credentials } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  constructor() { }

  get(key: string): Promise<{value: string}> {
    return SecureStoragePlugin.get({ key });
  }

  set(key: string, value: string): Promise<{value: boolean}> {
    return SecureStoragePlugin.set({ key, value });
  }

  remove(key: string): Promise<{value: boolean}> {
    return SecureStoragePlugin.remove({ key });
  }

  getCredentials(): Observable<Credentials> {
    return forkJoin({
      username: this.get(UserKeys.USERNAME),
      pwd: this.get(UserKeys.PASSWORD)
    }).pipe(
      map(credentials => {return {
        user_name: credentials.username.value,
        password: credentials.pwd.value
      }})
    )
  }

  getCredentialsAppversion(): Observable<Credentials> {
    return forkJoin({
      username: this.get(UserKeys.USERNAME),
      pwd: this.get(UserKeys.PASSWORD),
      appversion: this.get(UserKeys.APPVERSION)
    }).pipe(
      map(credentials => {return {
        user_name: credentials.username.value,
        password: credentials.pwd.value,
        app_version: credentials.appversion.value
      }})
    )
  }
}

export enum UserKeys {
  USERNAME = 'user_name',
  PASSWORD = 'password',
  LOGINTIMESTAMP = 'loginTimestamp',
  LOGOUTTIMESTAMP = 'logoutTimestamp',
  ISLOGGEDIN = 'isLoggedIn',
  APPVERSION = 'app_version'
}
