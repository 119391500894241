import { ExecutedWork } from './executedWork';
import { Exclude, Type } from 'class-transformer';
import PointGroup from 'signature_pad'

export class CheckReport {

  private _id: string;
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  private _customerId: number;
  public get customerId(): number {
    return this._customerId;
  }
  public set customerId(value: number) {
    this._customerId = value;
  }

  private _client: string;
  public get client(): string {
    return this._client;
  }
  public set client(value: string) {
    this._client = value;
  }

  private _mission: number;
  public get mission(): number {
    return this._mission;
  }
  public set mission(value: number) {
    this._mission = value;
  }

  private _commission: string;
  public get commission(): string {
    return this._commission;
  }
  public set commission(value: string) {
    this._commission = value;
  }

  private _address: string;
  public get address(): string {
    return this._address;
  }
  public set address(value: string) {
    this._address = value;
  }

  private _serviceUnit: string;
  public get serviceUnit(): string {
    return this._serviceUnit;
  }
  public set serviceUnit(value: string) {
    this._serviceUnit = value;
  }
  private _serviceUnitCompany: string;
  public get serviceUnitCompany(): string {
    return this._serviceUnitCompany;
  }
  public set serviceUnitCompany(value: string) {
    this._serviceUnitCompany = value;
  }
  private _serviceUnitAddress: string;
  public get serviceUnitAddress(): string {
    return this._serviceUnitAddress;
  }
  public set serviceUnitAddress(value: string) {
    this._serviceUnitAddress = value;
  }

  private _date: string;
  public get date(): string {
    return this._date;
  }
  public set date(value: string) {
    this._date = value;
  }
  private _timeBegin: string;
  public get timeBegin(): string {
    return this._timeBegin;
  }
  public set timeBegin(value: string) {
    this._timeBegin = value;
  }
  private _timeEnd: string;
  public get timeEnd(): string {
    return this._timeEnd;
  }
  public set timeEnd(value: string) {
    this._timeEnd = value;
  }

  private _km: number;
  public get km(): number {
    return this._km;
  }
  public set km(value: number) {
    this._km = value;
  }
  private _temp: number;
  public get temp(): number {
    return this._temp;
  }
  public set temp(value: number) {
    this._temp = value;
  }
  private _sun: boolean;
  public get sun(): boolean {
    return this._sun;
  }
  public set sun(value: boolean) {
    this._sun = value;
  }
  private _cloud: boolean;
  public get cloud(): boolean {
    return this._cloud;
  }
  public set cloud(value: boolean) {
    this._cloud = value;
  }
  private _rain: boolean;
  public get rain(): boolean {
    return this._rain;
  }
  public set rain(value: boolean) {
    this._rain = value;
  }
  private _snow: boolean;
  public get snow(): boolean {
    return this._snow;
  }
  public set snow(value: boolean) {
    this._snow = value;
  }

  private _note: string;
  public get note(): string {
    return this._note;
  }
  public set note(value: string) {
    this._note = value;
  }
  private _damageText: string;
  public get damageText(): string {
    return this._damageText;
  }
  public set damageText(value: string) {
    this._damageText = value;
  }
  @Type(() => ExecutedWork)
  private _executedWorks: ExecutedWork[];
  public get executedWorks(): ExecutedWork[] {
    return this._executedWorks;
  }
  @Type(() => ExecutedWork)
  public set executedWorks(value: ExecutedWork[]) {
    this._executedWorks = value;
  }
  private _isFinished: boolean = false;
  public get isFinished(): boolean {
    return this._isFinished;
  }
  public set isFinished(value: boolean) {
    this._isFinished = value;
  }

  private _checkedDamage: boolean;
  public get checkedDamage(): boolean {
    return this._checkedDamage;
  }
  public set checkedDamage(value: boolean) {
    this._checkedDamage = value;
  }

  private _checkedClean: boolean;
  public get checkedClean(): boolean {
    return this._checkedClean;
  }
  public set checkedClean(value: boolean) {
    this._checkedClean = value;
  }

  private _checkedWindowsill: boolean;
  public get checkedWindowsill(): boolean {
    return this._checkedWindowsill;
  }
  public set checkedWindowsill(value: boolean) {
    this._checkedWindowsill = value;
  }

  private _checkedGlasDamage: boolean;
  public get checkedGlasDamage(): boolean {
    return this._checkedGlasDamage;
  }
  public set checkedGlasDamage(value: boolean) {
    this._checkedGlasDamage = value;
  }

  private _checkedShutter: boolean;
  public get checkedShutter(): boolean {
    return this._checkedShutter;
  }
  public set checkedShutter(value: boolean) {
    this._checkedShutter = value;
  }

  private _maskOk: boolean;
  public get maskOk(): boolean {
    return this._maskOk;
  }
  public set maskOk(value: boolean) {
    this._maskOk = value;
  }
  private _specialText: string;
  public get specialText(): string {
    return this._specialText;
  }
  public set specialText(value: string) {
    this._specialText = value;
  }

  private _orderText: string;
  public get orderText(): string {
    return this._orderText;
  }
  public set orderText(value: string) {
    this._orderText = value;
  }

  private _signatureServiceUnit: string;
  public get signatureServiceUnit(): string {
    return this._signatureServiceUnit;
  }
  public set signatureServiceUnit(value: string) {
    this._signatureServiceUnit = value;
  }
  private _hasSignatureCustomer: boolean;
  public get hasSignatureCustomer(): boolean {
    return this._hasSignatureCustomer;
  }
  public set hasSignatureCustomer(value: boolean) {
    this._hasSignatureCustomer = value;
  }
  private _signatureCustomer: string;
  public get signatureCustomer(): string {
    return this._signatureCustomer;
  }
  public set signatureCustomer(value: string) {
    this._signatureCustomer = value;
  }
  private _noClientAvailable: boolean = false;
  public get noClientAvailable(): boolean {
    return this._noClientAvailable;
  }
  public set noClientAvailable(value: boolean) {
    this._noClientAvailable = value;
  }
  private _created: Date;
  public get created(): Date {
    return this._created;
  }
  public set created(value: Date) {
    this._created = value;
  }
  @Exclude({ toPlainOnly: true })
  private _signatureCustomerPoints: PointGroup[];
  public get signatureCustomerPoints(): PointGroup[] {
    return this._signatureCustomerPoints;
  }
  public set signatureCustomerPoints(value: PointGroup[]) {
    this._signatureCustomerPoints = value;
  }
  @Exclude({ toPlainOnly: true })
  private _signatureServiceUnitPoints: PointGroup[];
  public get signatureServiceUnitPoints(): PointGroup[] {
    return this._signatureServiceUnitPoints;
  }
  public set signatureServiceUnitPoints(value: PointGroup[]) {
    this._signatureServiceUnitPoints = value;
  }

  constructor() {
    this._created = new Date();
    this._hasSignatureCustomer = true;
    this._signatureCustomerPoints = [];
    this._signatureServiceUnitPoints = [];
    this._executedWorks = []
  }
}